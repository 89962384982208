import Navbar from "./components/Navbar";
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  return (
      <Router>
          <Navbar />

          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/termsofservice" element={<TermsOfService />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          </Routes>

          <div className="h-5"></div>
      </Router>
  );
}

export default App;
